import React, {Component} from 'react'
import {Button, Col, Form, Modal, Row, Tab, Tabs} from 'react-bootstrap'

import {connect} from "react-redux";
import _ from 'lodash';
import Sidebar from '../common/sidebar'
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import moment from "moment-timezone"
import {reportsActions} from "../../_actions";
import {UserIcon} from "../Svg";


class SurveyList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderInfo: [],
            ItemList: [],
            reId: [],
            excelFile: '',
            examDate: '',
            ItemId: [],
            itemId: [],
            type:1,
            sortField: '',
            sortType: 1,

            reasonErMsg: '',
            show: false,
            farmName: '',
            addExamPopup: false,
            messagePopup: 0, alertPopup: 0, emailPopup: 0,
            lat: 0, long: '',
            count: 30,
            totalCount: 0,
            userListCount: 0,
            dataCount: 0,
            filterHarvest: '',
            pageNo: 1,
            orderInfo1: [],
            userStatus: 0,
            profilePicPreview: '',

            examName: '',
            userId: '',
            deletePopup: false,
            examNameErrorMsg: '',
            examDateErrorMsg: '',
            SurveyList: [],
            // userId: localStorage.getItem('userId'),
            profilePicErrorMsg: '',
            loader: true
        }

    }

    componentDidMount() {
        this.getSurveys()

    }


    getSurveys = () => {
        this.setState({errors: ''});

        let self = this


        let {dispatch} = this.props;
        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let {pageNo, count, sortField, sortType, search,type} = this.state

        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let params = `?page=${pageNo - 1}&limit=${count}&sortField=${sortField ? sortField : ''}&sortType=${sortType ? sortType : ''}&surveyType=${type ? type : ''}&search=${search ? search : ''}`


        dispatch(reportsActions.getSurveys(params)).then(data => {


            this.setState({loader: false})
            let SurveyList = data.responseData.surveyDetails;

            SurveyList.filter(data=>{console.log(data.type===this.state.inventoryType,data.type,this.state.inventoryType,"SurveyListSurveyListdataccc")})
            let SurveyListCounts = data.responseData.count;

            if (data.statusCode == 1) {


                this.setState({
                    SurveyList: SurveyList,
                    SurveyListCounts: SurveyListCounts,


                })
            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });

    }
    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, pageNo: pageNo, count: 30}, () => {
            self.getSurveys();
        });
    }
    handleChanged = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                // emailError: ''
            }
        );
    }

    handleFilter = ele => {

        this.setState({
            loader: false, reasonErMsg: '', filterHarvest: ele, count: 10, pageNo: 1
        }, () => this.getOrderList())
    }

    handleClose = () => {
        this.setState({addExamPopup: false, deletePopup: false, showInvoice: false,})
    }

    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    checkVenues = (interest) => {
        let c = interest.length - 1
        // let data =        Date(dob)
        let a = ''
        !_.isEmpty(interest) && interest.map((val, i = 0) => {
            console.log(val)
            return a = a + val + (c > i ? ', ' : '')
        })

        return a
    }

    ConvertDob = (dob) => {
        // let data =        Date(dob)
        let data = moment(dob).format("MM/DD/YYYY")
        return data
    }



    handleShow = (item) => {
        this.setState({deletePopup: true, userId: item._id, userStatus: 3})
    }
    sendToParent = (e) => {
        this.setState({type: this.state.type,loader: true})
        this.getSurveys()
    }

    changeName = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            type: this.state.type,
            search: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 1000)
        })


    }

    inventoryType = (e) => {

        if (e == '1') {
            this.setState({ type: 1 ,search:''}, () => this.getSurveys());
        } else if (e == '2')
        {
            this.setState({ type: 2,search:'' }, () => this.getSurveys());
        }
    }
    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getSurveys()  )


    render() {
        let {  inventoryType,deletePopup, SurveyList,SurveyListCounts, errors, examDateErrorMsg, examDate, examName, examNameErrorMsg, profilePicErrorMsg, excelFile, filterHarvest, farmName, reasonErMsg, orderInfo, count, pageNo, totalCount, dataCount, ItemList, profilePicPreview} = this.state;


        return (
            <>
                <Sidebar/>
                <div className="body_container">
                    <div className="header_search header_search_custom ">



                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Surveys</h6>
                                                    <h6 className="mb-0">{SurveyListCounts}</h6>
                                                </div>&nbsp;
                                                <div>
                                                        <span className="user-icon icon-grey">
                                                           {/*<UserIcon/>*/}<i className="fa fa-exclamation-circle sizeCircle"
                                                                               aria-hidden="true"></i>

                                                        </span>
                                                </div>&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 offset-3">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                    <Form.Control type="text" placeholder="Search" name={"search"} onChange={this.changeName}/>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <div className="loader_wrapper">
                                    <div class="spinner-border text-info" role="status"></div>
                                </div> :
                                (<>
                                    <header className="header_content">
                                        <p>Surveys</p>
                                    </header>

                                    <div className="main_content">
                                        <div className="cm-inventory">
                                            <Tabs defaultActiveKey='2' onSelect={(e) => this.inventoryType(e)}
                                                  activeKey={this.state.type}
                                                  id="uncontrolled-tab-example">
                                                <Tab eventKey='2' title="Events">

                                                    <div className="cm-inventory_gridview">

                                                        <div class="table-responsive">
                                                            <table class="table cm_bottom_space">
                                                                <thead>
                                                                <tr class="broder_top_0">
                                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                                    <th scope="col" onClick={() => this.handleSort('created')}>Created
                                                                        On<i
                                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                                    </th>
                                                                    <th scope="col" onClick={() => this.handleSort('eventName')}>Event Name <i
                                                                        className=""></i></th>

                                                                    <th scope="col" onClick={() => this.handleSort('reportedUser')}>Created By<i
                                                                        className=""></i></th>
                                                                    <th scope="col" >Comments<i
                                                                        className=""></i></th>
                                                                    <th scope="col">Cover Charge<i className=""></i></th>
                                                                    <th scope="col">Dress Code<i className=""></i></th>
                                                                    <th scope="col">Crowd<i className=""></i></th>
                                                                    <th scope="col">Music <i className=""></i></th>
                                                                    {/*<th scope="col">userData<i className=""></i></th>*/}


                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {!_.isEmpty(SurveyList) && SurveyList.map((item, i) => (
                                                                    <tr key={item._id}>

                                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                                        <td>{this.ConvertDate(item.created)}</td>
                                                                        <td>{item.eventData  ? item.eventData.eventName  : ''}</td>
                                                                        <td>{item.userData && item.userData._id ? <Link to={`user-info/${item.userData._id}`}>{item.userData.firstName} {item.userData.lastName}</Link> : ''}</td>
                                                                        <td>{item?item.additionalComments:''}</td>
                                                                        <td>{item?item.coverCharge:''}</td>
                                                                        {/*<td>{item?item.dressCode:''}</td>*/}
                                                                        <td>{item && item.dressCode.length ? this.checkVenues(item.dressCode) : ''}</td>

                                                                        <td>
                                                                            {item && item.howIsCrowd==1?"Not packed":(item.howIsCrowd==2?"Moderate":(item.howIsCrowd==3?"Good amount of people":(item.howIsCrowd==4?"Super packed":'')))}
                                                                        </td>                                                                        {/*<td>{item?item.musicType:''}</td>*/}
                                                                        <td>{item && item.musicType.length ? this.checkVenues(item.musicType) : ''}</td>

                                                                    </tr>))
                                                                }


                                                                </tbody>


                                                            </table>
                                                            {/*{SurveyList && SurveyList.length ?*/}
                                                            {/*    <div*/}
                                                            {/*        className="d-flex justify-content-end cm-pagination">*/}
                                                            {/*        /!*{this.state.userListCount}*!/*/}
                                                            {/*        <Pagination*/}
                                                            {/*            itemClassPrev={'prevClass'}*/}
                                                            {/*            itemClassNext={'nextClass'}*/}
                                                            {/*            activePage={this.state.pageNo}*/}
                                                            {/*            itemsCountPerPage={this.state.count}*/}
                                                            {/*            totalItemsCount={this.state.SurveyListCounts}*/}
                                                            {/*            pageRangeDisplayed={3}*/}
                                                            {/*            onChange={this.handlePageChange}*/}
                                                            {/*        />*/}
                                                            {/*    </div> : ''}*/}
                                                            {_.isEmpty(this.state.SurveyList) ?
                                                                <h3 className="text-center">No data found</h3> : ''}
                                                            {reasonErMsg ?
                                                                <div className="text-center error ">{reasonErMsg}</div> : ''}
                                                        </div>
                                                    </div>
                                                </Tab>


                                                <Tab eventKey="1" title="Venues">
                                                    <div className="cm-inventory_gridview">
                                                        <div className="table-responsive">
                                                            <table className="table cm_bottom_space">
                                                                <thead>
                                                                <tr className="broder_top_0">
                                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                                    <th scope="col" onClick={() => this.handleSort('created')}>Created
                                                                        On<i
                                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                                    </th>
                                                                    <th scope="col" onClick={() => this.handleSort('venueName')}>Venue Name <i
                                                                        className=""></i></th>

                                                                    <th scope="col" onClick={() => this.handleSort('reportedUser')}>Created By<i
                                                                        className=""></i></th>
                                                                    <th scope="col" onClick={() => this.handleSort('reportedUser')}>Comments<i
                                                                        className=""></i></th>

                                                                    <th scope="col">Cover Charge <i className=""></i></th>
                                                                    <th scope="col">Dress Code <i className=""></i></th>
                                                                    <th scope="col">Crowd<i className=""></i></th>
                                                                    <th scope="col">Music<i className=""></i></th>
                                                                    {/*<th scope="col">userData<i className=""></i></th>*/}

                                                                </tr>
                                                                </thead>
                                                                <tbody>


                                                                {!_.isEmpty(SurveyList) && SurveyList.map((item, i) => (
                                                                    <tr key={item._id}>

                                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                                        <td>{this.ConvertDate(item.created)}</td>
                                                                        <td>{item.venueData  ? item.venueData.businessName  : ''}</td>
                                                                        <td>{item.userData && item.userData._id ? <Link to={`user-info/${item.userData._id}`}>{item.userData.firstName} {item.userData.lastName}</Link> : ''}</td>
                                                                        <td>{item?item.additionalComments:''}</td>
                                                                        <td>{item?item.coverCharge:''}</td>

                                                                        <td>{item && item.dressCode.length ? this.checkVenues(item.dressCode) : ''}</td>
                                                                        {/*//1=not packed, 2=moderate, 3=good amount of people, 4=super packed*/}

                                                                        <td>
                                                                            {item && item.howIsCrowd==1?"Not packed":(item.howIsCrowd==2?"Moderate":(item.howIsCrowd==3?"Good amount of people":(item.howIsCrowd==4?"Super packed":'')))}
                                                                        </td>
                                                                        {/*<td>{item?item.musicType:''}</td>*/}
                                                                        <td>{item && item.musicType.length ? this.checkVenues(item.musicType) : ''}</td>

                                                                    </tr>))
                                                                }


                                                                </tbody>


                                                            </table>
                                                            {/*{SurveyList && SurveyList.length ?*/}
                                                            {/*    <div*/}
                                                            {/*        className="d-flex justify-content-end cm-pagination">*/}
                                                            {/*        /!*{this.state.userListCount}*!/*/}
                                                            {/*        <Pagination*/}
                                                            {/*            itemClassPrev={'prevClass'}*/}
                                                            {/*            itemClassNext={'nextClass'}*/}
                                                            {/*            activePage={this.state.pageNo}*/}
                                                            {/*            itemsCountPerPage={this.state.count}*/}
                                                            {/*            totalItemsCount={this.state.SurveyListCounts}*/}
                                                            {/*            pageRangeDisplayed={3}*/}
                                                            {/*            onChange={this.handlePageChange}*/}
                                                            {/*        />*/}
                                                            {/*    </div> : ''}*/}
                                                            {_.isEmpty(this.state.SurveyList) ?
                                                                <h3 className="text-center">No data found</h3> : ''}
                                                            {reasonErMsg ?
                                                                <div
                                                                    className="text-center error ">{reasonErMsg}</div> : ''}
                                                        </div>
                                                    </div>
                                                </Tab>

                                            </Tabs>

                                        </div>
                                    </div>


                                </>)}


                        </div>



                    </div>
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addExamPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Exam</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.handlAddExam}>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Name:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examName"
                                                          value={examName}
                                                          placeholder="Exam Name"/>
                                            {examNameErrorMsg ?
                                                <div
                                                    className="error">{examNameErrorMsg}</div> : null}
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Date:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examDate"
                                                          value={examDate}
                                                          placeholder="Exam Date "/>
                                            {examDateErrorMsg ?
                                                <div
                                                    className="error">{examDateErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {errors ? errors : ''}
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.viewMode}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                {/*<Button variant="warning" className="text-light"*/}
                                {/*        onClick={() => this.handleItemAction(ItemList._id, 2)}*/}
                                {/*>*/}
                                {/*    ExamList Fulfilled*/}
                                {/*</Button>*/}

                            </div>
                            <div className="print_button orderabs">
                              <Link className="btn btn-light "><i
                                    className="fas fa-print" title="Invoicddddde"
                                ></i>Print</Link>

                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete this user?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        )
    }
}

// export default


function mapStateToProps(state) {
    const {props, login, user, mapReducer} = state;
    return {
        props,
        login,
        user,
        mapReducer
    }
}

export default connect(mapStateToProps)(SurveyList);
// export default Settings
